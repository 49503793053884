const projects = [
    {
        name: "Dashy",
        url: 'https://github.com/kvaishak/dashy-web',
        img: 'dashy.png',
        description: "A simple todo application using Native Javascript - authentication and storage handled by Firebase",
        tags: ["html", 'javascript', 'firebase', 'bootstrap']
    },
    {
        name: "Passphrase",
        url: 'https://chrome.google.com/webstore/detail/passphrase/dmdajbcffmnegdflmhcikhmaagmdknhj?hl=en&authuser=0',
        img: 'passphrase.png',
        description: "A passphrase generator chrome extension using react and typescript",
        tags: ["typescript", 'react', 'extension']
    },
    {
        name: "Weather",
        url: 'https://github.com/kvaishak/weather',
        img: 'weather.png',
        description: "A Node.js application using ExpressJS framework - database using nedb - Maps using Leaflet.js",
        tags: ["node", 'expressJS', 'nedb']
    },
    {
        name: "Shorty",
        url: 'https://github.com/kvaishak/shorty',
        img: 'shorty.png',
        description: "A URL shortner app - using jsonbin.io",
        tags: ["javascript", 'html', 'bootstrap']
    },
    {
        name: "Dashy-Flutter",
        url: 'https://github.com/kvaishak/dashy',
        img: 'dashy_f.png',
        description: "A Flutter based Hybrid mobile application for dashy",
        tags: ["dart", 'firebase']
    },
    {
        name: "Cancer Classification",
        url: 'https://github.com/kvaishak/breast_cancer_classifier',
        img: 'cancer.png',
        description: "Machine Learning Model to classify tumors based on snapshots collected by Fine Needle Aspirate(FNA) Technique",
        tags: ["python", 'jupiter_notebook', 'ml']
    },
    {
        name: "Fashion Classification",
        url: 'https://github.com/kvaishak/fashion_class_classifier',
        img: 'fashion.png',
        description: "Using Convolutional Neural Network to accurately classify clothing and other fashion items from their images",
        tags: ["python", 'jupiter_notebook', 'matplot', 'keras']
    },
];

export default projects;