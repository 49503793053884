import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Project = (props) => {

    const projectData = props.projectData;

    const allImageData = useStaticQuery(graphql`
    query MyQuery {
        allImageSharp {
          edges {
            node {
              id
              fluid {
               aspectRatio
                base64
                sizes
                src
                srcSet
                originalName
              }
            }
          }
        }
      }
      
    `)

      
    const currentImageData = allImageData.allImageSharp.edges.find(image => image.node.fluid.originalName === projectData.img);


    return ( 
    <React.Fragment>
        <a href={projectData.url} className="project">
                <h4>{projectData.name}</h4>

                <div className="image">
                    <Img fluid={currentImageData.node.fluid} alt={projectData.name}/>
                </div>

                <p>
                    {projectData.description}
                </p>

                <div className="tags">
                    {projectData.tags.map(tag=><span key={tag}>{tag}</span>)}
                </div>
            </a>
    </React.Fragment>
    );
}
 
export default Project;

