import * as React from "react"
import Header from "../components/Header";
import projects from "../content/projects/projects";
import Metadata from "../components/Metadata";

import Project from "../components/Project";
import Footer from "../components/Footer";

const IndexPage = () => {

    return(
        <div className="container">

        <Metadata title="Projects" description="My Projects" />

        <Header />

    
        <article>

            <h3 className="main">Projects</h3>
            <div className="projects">
                {projects.map(project => <Project projectData={project} key={project.name}/>)}
            </div>
        </article>

        <Footer />
    </div>
    )
}

export default IndexPage;

